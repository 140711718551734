<template>
  <div class="container">
    <Box :title="$t('4f3d806')" class="pane">
      <div class="list">
        <div
          v-for="item in list"
          :key="item.id"
          class="item"
          @click="jump(item.id)"
        >
          <div class="title">
            {{ item.title | textFilter }}
          </div>
          <div class="side">
            <div class="day">{{ item.createTime | date('MM-DD') }}</div>
            <div class="year">{{ item.createTime | date('YYYY') }}</div>
          </div>
        </div>
        <div v-if="!list.length" class="empty">暂无数据</div>
      </div>
      <div class="pager">
        <el-pagination
          :current-page="query.page"
          :page-size="query.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
          @current-change="getList"
        />
      </div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Box'
import Api from '@/api/information'

export default {
  components: {
    Box,
  },
  data() {
    return {
      list: [],
      total: 0,
      loading: true,
      query: {
        page: 1,
        size: 10,
      },
    }
  },
  mounted() {
    this.getList(1)
  },
  methods: {
    getList(page) {
      this.loading = true

      Api.getNoticeList({ ...this.query, page })
        .then((res) => {
          this.query.page = page
          this.list = res.content
          this.total = res.page.total
        })
        .catch((err) => {})
        .then((res) => {
          this.loading = false
        })
    },
    jump(id) {
      this.$router.push({
        path: '/information/noticeDetail',
        query: { id },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  height: 100%;
}

.pane {
  height: 100%;
  margin-bottom: 0;
}

.empty {
  padding: 30px;
  color: #999999;
  font-size: 14px;
  text-align: center;
}

.pager {
  padding: 20px 20px 0;
  text-align: right;
}

.list {
  margin-top: -30px;
}

.item {
  height: 96px;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #f3f3f3;

  &:hover {
    .title,
    .side .day,
    .side .year {
      color: #00a4ff;
    }
  }

  .title {
    font-size: 16px;
    color: #333333;
    height: 28px * 2;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .side {
    flex-shrink: 0;
    padding-left: 20px;
    margin-left: auto;
    text-align: right;
    .day {
      font-size: 20px;
      color: #666666;
      margin-bottom: 12px;
    }
    .year {
      font-size: 14px;
      color: #999999;
    }
  }
}
</style>
